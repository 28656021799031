<template>
    <v-app>
        <!-- begin:: Content -->
        <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="kt-portlet">
                <div class="kt-portlet__body kt-portlet__body--fit">
                    <div class="kt-invoice-2">
                        <div class="kt-invoice__head">
                            <div class="kt-invoice__container">
                                <div class="kt-invoice__brand d-flex justify-content-between pb-10 pb-md-20">
                                    <h4 class=" display-2 font-weight-boldest mb-10">INVOICE</h4>
                                    <div href="#" class="kt-invoice__logo d-flex flex-column align-items-md-end px-0">
                                        <a href="#" class=""><img src="/media/logos/logo_small.jpeg"></a>
                                        <span class="kt-invoice__desc d-flex flex-column align-items-md-end opacity-70">
															<span>Kamalpokhari, Kathmandu, Nepal</span>
															<span>Phone :  +977 1 4534990, 4534178</span>
															<span>Email : info@kist.edu.np</span>
														</span>
                                    </div>
                                </div>
<!--                                <table class="kt-invoice__brand pb-10 pb-md-20">-->
<!--                                    <tr>-->
<!--                                        <td><h4 class=" display-2 font-weight-boldest mb-10">INVOICE</h4></td>-->
<!--                                        <td>-->
<!--                                            <div href="#" class="kt-invoice__logo d-flex flex-column align-items-md-end px-0">-->
<!--                                                <a href="#" class="mb-5"><img src="/media/logos/logo_small.jpeg"></a>-->
<!--                                                <span class="kt-invoice__desc d-flex flex-column align-items-md-end opacity-70">-->
<!--															<span>Kamalpokhari, Kathmandu, Nepal</span>-->
<!--															<span>Phone :  +977 1 4534990, 4534178</span>-->
<!--															<span>Email : info@kist.edu.np</span>-->
<!--														</span>-->
<!--                                            </div>-->
<!--                                        </td>-->
<!--                                    </tr>-->
<!--                                </table>-->
                                <div class="border-bottom w-100"></div>
                                <div class="kt-invoice__items d-flex justify-content-between pt-6">
                                    <div class="kt-invoice__item d-flex flex-column flex-root">
                                        <span class="kt-invoice__subtitle font-weight-bolder mb-2">DATE</span>
                                        <span class="kt-invoice__text opacity-70">Dec 12, 2017</span>
                                    </div>
                                    <div class="kt-invoice__item d-flex flex-column flex-root">
                                        <span class="kt-invoice__subtitle font-weight-bolder mb-2">INVOICE NO.</span>
                                        <span class="kt-invoice__text opacity-70">GS 000014</span>
                                    </div>
                                    <div class="kt-invoice__item d-flex flex-column flex-root">
                                        <span class="kt-invoice__subtitle font-weight-bolder mb-2">INVOICE TO.</span>
                                        <span class="kt-invoice__text opacity-70"><strong> Mohammad Sarfaraz</strong><br>Kathmandu, Kathmandu, Kathmandu Metropolitan City, Bagmati, Nepal</span>
                                        <span class="kt-invoice__text opacity-70"> Phone : 9851126433<br>Email : mbneupane@kist.edu.np</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="kt-invoice__body">
                            <div class="kt-invoice__container">
                                <div class="">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th class="pl-0 font-weight-bold text-uppercase">DESCRIPTION</th>
                                            <th class="pl-0 font-weight-bold text-uppercase">AMOUNT</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr class="font-weight-boldest">
                                            <td class="pt-6">Enrollment
                                            <p>
                                                Bachelor in Information Technology
                                            </p></td>
                                            <td class="pt-6 text-primary kt-font-lg">Rs. 3200.00</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="kt-invoice__footer justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0">
                            <div class="kt-invoice__container">
                                <div class="">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th pl-0 font-weight-bold text-uppercase>Payment Method</th>
                                            <th pl-0 font-weight-bold text-uppercase>Transaction ID</th>
                                            <th pl-0 font-weight-bold text-uppercase>DUE DATE</th>
                                            <th pl-0 font-weight-bold text-uppercase>TOTAL AMOUNT</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr class="font-weight-boldest">
                                            <td class="pt-6">Esewa, Cash, Bank</td>
                                            <td class="pt-6">12345678909</td>
                                            <td class="pt-6">Jan 07, 2018</td>
                                            <td class="pt-6 kt-font-danger font-size-h3 kt-font-boldest">Rs. 20,600.00</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p> Recieved By : John Duo</p>

                            </div>
                        </div>
                        <div class="kt-invoice__actions">
                            <div class="kt-invoice__container">
                                <div class="d-flex justify-space-between">
                                    <button type="button" class="btn btn-label-brand btn-bold btn-light-primary" onclick="window.print();">Download Invoice</button>
                                    <button type="button" class="btn btn-brand btn-bold btn-primary" onclick="window.print();">Print Invoice</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </v-app>
</template>
<script>

    export default {
        name: "cart",

        data() {
            return {

            };
        },
        mounted() {

        },
        methods: {

        }
    };
</script>

<style scoped lang="scss">
    .kt-font-danger {
        color: #cc0000;
    }
    .kt-invoice-2 {
        padding: 5rem 0rem;
        & > * + * {
            margin-top: 2rem;
        }
        .kt-invoice {
            .table td {
                vertical-align: middle;
            }
            &__brand {
                display: flex;
                justify-content: space-between;

                @media only screen and (max-width: 767px) {
                    flex-direction: column;
                }
            }

            &__container {
                flex: 0 0 75%;
                max-width: 75%;
                margin: 0 auto;
            }

            &__logo {
                img {
                    width: 100px;
                }
            }
        }

        .table tr.border-top-0 td {
            border-top: none;
        }


        @media only print {
            padding: 6rem 0rem;
            margin: 0 auto;
            width: 100%;
            height: 100%;
            & > * + * {
                margin-top: 2rem;
            }

            .kt-invoice {
                &__brand {
                    @media (max-width: 767px) {
                        flex-direction: row;
                        
                        .kt-invoice__logo img {
                            float: left;
                        }
                    }


                }
                &__actions {
                    display: none;
                }

                &__container {
                    /*flex: 0 0 75%;*/
                    /*max-width: 75%;*/
                    /*margin: 0 auto;*/
                }

                .table {
                    background-color: transparent;
                }
            }
        }
    }

    .v-application--wrap {
        @media only print {
            .card, .v-dialog__container {
                display: none;
            }
        }
    }

</style>

<style lang="scss">


    @media only print {
        .footer#kt_footer, #kt_scrolltop.scrolltop,
        .header#kt_header, .header-top, .header,
        #kt_header_mobile {
            display: none !important;
        }
        body {
            background-color: #fff;
            font-size: 15px !important;
        }
    }
</style>